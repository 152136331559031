import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { loginRedirectionGuardService } from 'src/services/login-redirection.guard.service';

import { CustomerModuleKey } from '../models/customer';
import { activeSessionGuard } from '../services/active-session.guard';
import { categoriesGuard } from '../services/categories.guard';
import { courseAccessGuard } from '../services/course-access.guard';
import { courseDetailGuard } from '../services/course-detail.guard';
import { helpCategoryGuard } from '../services/help-category.guard';
import { moduleGuard } from '../services/module.guard';
import { AuthService } from '../shared-components/ng-login/auth/auth.service';
import { authGuard } from '../shared-components/ng-login/auth/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./main/main.component').then((mod) => mod.MainComponent),
    canActivate: [authGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/overview'
      },
      {
        path: 'overview',
        loadComponent: () => import('./overview/overview.component').then((mod) => mod.OverviewComponent)
      },
      {
        path: 'categories/:id',
        loadComponent: () =>
          import('./category/category-detail/category-detail.component').then((mod) => mod.CategoryDetailComponent),
        canActivate: [categoriesGuard]
      },
      {
        path: 'webinar',
        canActivate: [moduleGuard],
        data: {
          module: CustomerModuleKey.WEBINARS
        },
        loadComponent: () => import('./webinar-main/webinar-main.component').then((mod) => mod.WebinarMainComponent),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'search'
          },
          {
            path: 'search',
            loadComponent: () =>
              import('./webinar-main/webinar-search/webinar-search.component').then((mod) => mod.WebinarSearchComponent)
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./webinar/webinar-detail/webinar-detail.component').then((mod) => mod.WebinarDetailComponent)
          }
        ]
      },
      {
        path: 'my-learning',
        loadComponent: () => import('./my-learning/my-learning.component').then((mod) => mod.MyLearningComponent),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'courses/in-progress'
          },
          {
            path: 'courses',
            children: [
              {
                path: 'in-progress',
                loadComponent: () =>
                  import('./my-learning/courses-in-progress/courses-in-progress.component').then(
                    (mod) => mod.CoursesInProgressComponent
                  )
              },
              {
                path: 'saved',
                loadComponent: () =>
                  import('./my-learning/saved-courses/saved-courses.component').then((mod) => mod.SavedCoursesComponent)
              },
              {
                path: 'completed',
                loadComponent: () =>
                  import('./my-learning/completed-courses/completed-courses.component').then(
                    (mod) => mod.CompletedCoursesComponent
                  )
              }
            ]
          },
          {
            path: 'articles',
            children: [
              {
                path: 'saved',
                loadComponent: () =>
                  import('./my-learning/saved-articles/saved-articles.component').then(
                    (mod) => mod.SavedArticlesComponent
                  )
              }
            ]
          }
          // Not allowed route path until we have a report
          // https://nucleoo.atlassian.net/jira/software/c/projects/EDUNI/boards/138?selectedIssue=EDUNI-218

          // {
          //   path: 'my-follow-up',
          //   component: MyInsightsComponent
          // }
        ]
      },
      {
        path: 'account',
        loadComponent: () => import('./account/account.component').then((mod) => mod.AccountComponent),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'profile'
          },
          {
            path: 'profile',
            loadComponent: () => import('./account/profile/profile.component').then((mod) => mod.ProfileComponent)
          },
          {
            path: 'payments',
            loadComponent: () =>
              import('./account/bill-and-payments/bill-and-payments.component').then(
                (mod) => mod.BillAndPaymentsComponent
              )
          },
          {
            path: 'degrees',
            loadComponent: () => import('./account/degree/degree.component').then((mod) => mod.DegreeComponent)
          },
          {
            path: 'my-documents',
            loadComponent: () =>
              import('./account/my-documents/my-documents.component').then((mod) => mod.MyDocumentsComponent)
          },
          {
            path: 'security',
            loadComponent: () => import('./account/security/security.component').then((mod) => mod.SecurityComponent)
          },
          {
            path: 'my-subscription',
            loadComponent: () =>
              import('./account/my-subscription/my-subscription.component').then((mod) => mod.MySubscriptionComponent)
          }
        ]
      },
      {
        path: 'course/:id',
        loadComponent: () =>
          import('./course/course-detail/course-detail.component').then((mod) => mod.CourseDetailComponent),
        canActivate: [courseDetailGuard]
      },
      {
        path: 'marketplace-course/:id/detail',
        loadComponent: () =>
          import('./course/course-detail/course-detail.component').then((mod) => mod.CourseDetailComponent),
        canActivate: [courseDetailGuard]
      },
      {
        path: 'shared/course/:id',
        loadComponent: () =>
          import('./course/course-detail/course-detail.component').then((mod) => mod.CourseDetailComponent),
        canActivate: [courseDetailGuard]
      },
      {
        path: 'course/:id/moodle',
        loadComponent: () => import('./moodle-course/moodle-course.component').then((mod) => mod.MoodleCourseComponent),
        canActivate: [courseAccessGuard]
      },
      {
        path: 'courses',
        loadComponent: () =>
          import('./course/course-search/course-search.component').then((mod) => mod.CourseSearchComponent)
      },
      {
        path: 'articles',
        loadComponent: () =>
          import('./articles/article-list/article-list.component').then((mod) => mod.ArticleListComponent)
      },
      {
        path: 'article/:id',
        loadComponent: () =>
          import('./articles/article-detail/article-detail.component').then((mod) => mod.ArticleDetailComponent)
      },
      {
        path: 'help-center',
        children: [
          {
            path: '',
            loadComponent: () => import('./help-center/help-center.component').then((mod) => mod.HelpCenterComponent)
          },
          {
            path: ':option',
            loadComponent: () =>
              import('./help-center/help-center-category/help-center-category.component').then(
                (mod) => mod.HelpCenterCategoryComponent
              ),
            canActivate: [helpCategoryGuard]
          }
        ]
      }
    ]
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then((mod) => mod.LoginComponent),
    canActivate: [activeSessionGuard]
  },
  {
    path: 'course/:idSimo/checkout',
    loadComponent: () => import('./login/login.component').then((mod) => mod.LoginComponent),
    canActivate: [loginRedirectionGuardService]
  },
  {
    path: 'course/:idSimo/request',
    loadComponent: () => import('./login/login.component').then((mod) => mod.LoginComponent),
    canActivate: [loginRedirectionGuardService]
  },
  {
    path: 'subscription/checkout',
    loadComponent: () => import('./login/login.component').then((mod) => mod.LoginComponent),
    canActivate: [loginRedirectionGuardService]
  },
  {
    path: 'email-pass-login',
    loadComponent: () =>
      import('./login/email-pass-login/email-pass-login.component').then((mod) => mod.EmailPassLoginComponent),
    canMatch: [(): boolean => !environment.production]
  },
  {
    path: 'hub',
    loadComponent: () => import('./hub/hub.component').then((mod) => mod.HubComponent),
    canMatch: [(): boolean => inject(AuthService).isValidHubDomain()]
  },
  {
    path: 'ui-guide',
    loadComponent: () => import('./design-guide/ui-guide.component').then((mod) => mod.UIGuideComponent)
  },
  // In order to avoid multiple calls to the backend keep wildcard as the last route in the route list
  {
    path: '**',
    redirectTo: ''
  }
];
