import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { environment } from '../environments/environment';
import { CustomerModuleKey } from '../models/customer';
import { CategoryService } from './category.service';
import { CustomerService } from './customer.service';

export const categoriesGuard: CanActivateFn = async (route) => {
  const router = inject(Router);
  const categoryService = inject(CategoryService);
  const customerService = inject(CustomerService);

  try {
    if (localStorage.getItem('test_mode') && environment.name !== 'prod') {
      return true;
    }

    const categoryId = route.paramMap.get('id');
    if (customerService.isCustomerModuleEnabled(CustomerModuleKey.ONLYFREECOURSESWEB)) {
      const freeCategories = await lastValueFrom(categoryService.getCategoriesFromFreeCourses());

      if (freeCategories.items.some((category) => category._id === Number(categoryId))) {
        return true;
      } else {
        return router.parseUrl('overview');
      }
    }

    return true;
  } catch {
    return router.parseUrl('overview');
  }
};
